import { type ComponentType, lazy } from 'react'

import { GuiddeLoad } from 'UI/Components'

import { paths } from 'app/paths'

import { delay } from 'modules'
import { roles } from 'hooks'

const lazyWithRetry = (componentImport: () => Promise<{ default: ComponentType<any> }>) => {
    // First time we cannot load the component (chunk) we force user to reload the page. It happens every time after deploy.
    // The chunks hash changes so when user open another page without reload - it tries to load unavailable chunk. After reload it fixes itself
    // WARNING: DO NOT REMOVE THIS LOGIC
    return lazy(async () => {
        const refreshItemName = 'page-has-been-force-refreshed'
        const refreshed = JSON.parse(window.sessionStorage?.getItem(refreshItemName) || 'false')

        try {
            const component = await componentImport()
            window.sessionStorage?.setItem(refreshItemName, 'false')
            return component
        } catch (error) {
            console.error('Lazy load error: ', { refreshed, error, componentImport })
            if (!refreshed) {
                window.sessionStorage?.setItem(refreshItemName, 'true')
                delay(5000).then(() => window.location.reload())
                return {
                    default: () => (
                        <GuiddeLoad message="Magically create video documentation with AI in seconds!" />
                    )
                }
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            window.sessionStorage?.setItem(refreshItemName, 'false')
            throw error
        }
    })
}

// Pages
const PageIndex = lazyWithRetry(() => import('UI/Routes/PageIndex'))
const PageHome = lazyWithRetry(() => import('UI/Routes/home/PageHome'))
const PageViewPlaybook = lazyWithRetry(() => import('UI/Routes/main/playbook/PageViewPlaybook'))
const PageEditPlaybook = lazyWithRetry(() => import('UI/Routes/main/playbook/PageEditPlaybook'))
const PageViewPlaylist = lazyWithRetry(() => import('UI/Routes/main/playlist/PageViewPlaylist'))
const PageEditPlaylist = lazyWithRetry(() => import('UI/Routes/main/playlist/PageEditPlaylist'))

const PageOnboarding = lazyWithRetry(() => import('UI/Routes/onboarding/PageOnboarding'))

const PageEditQuickGuidde = lazyWithRetry(
    () => import('UI/Routes/quick-guidde/PageEditQuickGuidde')
)
const PageSignIn = lazyWithRetry(() => import('UI/Routes/signIn/PageSignIn'))
const PageSignUp = lazyWithRetry(() => import('UI/Routes/signIn/PageSignUp'))
const PageProfile = lazyWithRetry(() => import('UI/Routes/profile/PageProfile'))
const PageFinishSignIn = lazyWithRetry(() => import('UI/Routes/signIn/PageFinishSignIn'))
const PageJoinSpace = lazyWithRetry(() => import('UI/Routes/signIn/PageJoinSpace'))
const PageAppsManagement = lazyWithRetry(
    () => import('UI/Routes/apps-management/PageAppsManagement')
)
const PageBackoffice = lazyWithRetry(() => import('UI/Routes/backoffice/PageBackoffice'))
const PageAccountSettings = lazyWithRetry(
    () => import('UI/Routes/account-settings/PageAccountSettings')
)
const PageSlackAuth = lazyWithRetry(() => import('UI/Routes/slack-auth/PageSlackAuth'))
const PageInsights = lazyWithRetry(() => import('UI/Routes/insights/charts/PageInsights'))
const PageInsightsMetricsTable = lazyWithRetry(
    () => import('UI/Routes/insights/table/PageInsightsMetricsTable')
)
const PageViewSpace = lazyWithRetry(() => import('UI/Routes/space/PageViewSpace'))
const PageSpaceLibrary = lazyWithRetry(() => import('UI/Routes/space-library/PageSpaceLibrary'))
const PageAllSpaces = lazyWithRetry(() => import('UI/Routes/spaces/PageAllSpaces'))
const PageActivity = lazyWithRetry(() => import('UI/Routes/activity/PageActivity'))
const PageSearch = lazyWithRetry(() => import('UI/Routes/search/PageSearch'))
const PageIntegrations = lazyWithRetry(() => import('UI/Routes/integrations/PageIntegrations'))
const PagePublicPlaybook = lazyWithRetry(() => import('UI/Routes/share/PagePublicPlaybook'))
const PagePublicPlaylist = lazyWithRetry(() => import('UI/Routes/share/PagePublicPlaylist'))
const PageNotifications = lazyWithRetry(() => import('UI/Routes/notifications/PageNotifications'))
const PageSsoLogin = lazyWithRetry(() => import('UI/Routes/signIn/PageSsoLogin'))
const PageExtDownloaded = lazyWithRetry(
    () => import('UI/Routes/extension-download/PageExtDownloaded')
)
const PageBrandKit = lazyWithRetry(() => import('UI/Routes/brand-kit/PageBrandKit'))
const PageMyVideos = lazyWithRetry(() => import('UI/Routes/my-videos/PageMyVideos'))
const PageExtUninstalled = lazyWithRetry(() => import('UI/Routes/PageExtUninstalled'))
const PageTrash = lazyWithRetry(() => import('UI/Routes/trash/PageTrash'))

export type RouteType = {
    path: string | Array<string>
    isPrivate?: boolean
    component: JSX.Element
    role?: string | Array<string>
    feature?: string
    hasUsageBanner?: boolean
}

export const routes: Array<RouteType> = [
    {
        path: paths.index,
        component: <PageIndex />
    },
    {
        path: [paths.searchPlaybook, paths.searchPlaylist],
        isPrivate: true,
        component: <PageSearch />
    },
    {
        path: paths.onboarding,
        isPrivate: true,
        component: <PageOnboarding />
    },
    {
        path: [paths.brandKitDetails, paths.brandKit],
        isPrivate: true,
        component: <PageBrandKit />,
        hasUsageBanner: true
    },
    {
        path: paths.myVideos,
        isPrivate: true,
        component: <PageMyVideos />,
        hasUsageBanner: true
    },
    {
        path: paths.home,
        isPrivate: true,
        component: <PageHome />,
        hasUsageBanner: true
    },
    {
        path: paths.playbookDetails,
        isPrivate: false,
        component: <PageViewPlaybook />
    },
    {
        path: paths.editPlaybook,
        isPrivate: true,
        component: <PageEditPlaybook />
    },
    {
        path: paths.playlistDetails,
        isPrivate: false,
        component: <PageViewPlaylist />
    },
    {
        path: paths.editPlaylist,
        isPrivate: true,
        component: <PageEditPlaylist />
    },
    {
        path: paths.editQuickGuidde,
        isPrivate: true,
        component: <PageEditQuickGuidde />
    },
    {
        path: paths.appsManagement,
        isPrivate: true,
        role: roles.superAdmin,
        component: <PageAppsManagement />
    },
    {
        path: paths.backoffice,
        isPrivate: true,
        component: <PageBackoffice />
    },
    {
        path: paths.profile,
        isPrivate: true,
        component: <PageProfile />
    },
    {
        path: [paths.login, paths.join],
        isPrivate: false,
        component: <PageSignIn />
    },
    {
        path: [paths.ssoLogin, paths.ssoTenantLogin],
        isPrivate: false,
        component: <PageSsoLogin />
    },
    {
        path: [paths.signup],
        isPrivate: false,
        component: <PageSignUp />
    },
    {
        path: paths.welcome,
        isPrivate: false,
        component: <PageExtDownloaded />
    },
    {
        path: paths.emailLink,
        isPrivate: false,
        component: <PageFinishSignIn />
    },
    {
        path: paths.joinSpace,
        isPrivate: false,
        component: <PageJoinSpace />
    },
    {
        path: [paths.accountSettings],
        isPrivate: true,
        role: roles.admin,
        component: <PageAccountSettings />
    },
    {
        path: paths.notifications,
        isPrivate: true,
        component: <PageNotifications />
    },
    {
        path: paths.slackAuth,
        isPrivate: true,
        component: <PageSlackAuth />
    },
    {
        path: paths.insights,
        isPrivate: true,
        component: <PageInsights />,
        role: [roles.contentManager, roles.admin]
    },
    {
        path: paths.insightsTable,
        isPrivate: true,
        component: <PageInsightsMetricsTable />,
        role: [roles.contentManager, roles.admin]
    },
    {
        path: paths.spaces,
        isPrivate: true,
        component: <PageAllSpaces />,
        hasUsageBanner: true
    },
    {
        path: [paths.spaceLibraryFolder, paths.spaceLibrary],
        isPrivate: true,
        component: <PageSpaceLibrary />,
        hasUsageBanner: true
    },
    {
        path: paths.spaceDetails,
        isPrivate: true,
        component: <PageViewSpace />
    },
    {
        path: paths.activity,
        isPrivate: true,
        component: <PageActivity />,
        role: [roles.contentManager, roles.admin],
        hasUsageBanner: true
    },
    {
        path: [paths.integrations, paths.guiddeInside, paths.broadcast],
        isPrivate: true,
        component: <PageIntegrations />
    },
    {
        path: [paths.trash],
        isPrivate: true,
        role: roles.team,
        component: <PageTrash />
    },
    {
        path: paths.publicPlaybook,
        component: <PagePublicPlaybook />
    },
    {
        path: paths.publicPlaylist,
        component: <PagePublicPlaylist />
    },
    {
        path: paths.extUninstalled,
        component: <PageExtUninstalled />
    }
]
