import { type ReactNode, memo } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { Container, Toolbar, Hidden, Box } from '@mui/material'

import { Menu } from 'UI/Components/Menu'

import { useAuth } from 'hooks'

import { paths } from 'app/paths'
import { planNotificationheight, PlanUsageNotification } from './PlanUsageNotification'
import { usePlanUsageNotification } from './use-plan-usage-notification'

type Props = {
    children: ReactNode
}

export const AppContainer = memo(({ children }: Props) => {
    const { isNotLogged, uid } = useAuth()

    const isOnboardingPage = useRouteMatch(paths.onboarding)?.isExact

    const { showUsageBanner, ...usageParams } = usePlanUsageNotification()

    return (
        <Box sx={showUsageBanner ? { height: '100vh', overflow: 'hidden' } : {}}>
            {showUsageBanner && (
                <>
                    <PlanUsageNotification {...usageParams} />
                    <Box height={planNotificationheight} />
                </>
            )}
            <Box display="flex">
                {uid && (
                    <Menu
                        toolbar={showUsageBanner ? <Box height={planNotificationheight} /> : null}
                    />
                )}
                <Container
                    maxWidth={false}
                    style={{
                        padding: 0,
                        height: showUsageBanner
                            ? `calc(100vh - ${planNotificationheight}px)`
                            : 'auto',
                        overflowY: 'auto'
                    }}
                >
                    {!isNotLogged && !isOnboardingPage && (
                        <Hidden implementation="css" mdUp>
                            <Toolbar />
                        </Hidden>
                    )}
                    {children}
                </Container>
            </Box>
        </Box>
    )
})
