import { memo, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { CircularProgress } from '@mui/material'

import { Button } from '@guidde/design-system'

import {
    type ImageShapeType,
    type QuickGuiddeType,
    Shape,
    type StepType,
    type VideoShapeType
} from 'app/types'
import {
    type TimelineChunksType,
    type TimelineSnapshotChunkType,
    type TimelineVideoChunkType
} from './types'

import { VoiceOverContext } from 'UI/Routes/quick-guidde/VoiceOverProvider'
import { getLayerCoordinates } from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel/AddNewStep'

import { checkIsSnapshotChunk, checkIsVideoChunk } from './splitAndTrimUtils'
import { getFirebaseUrl, logToAnalytics, uploadWithSignedUrl, uuid } from 'modules'
import { setQuickGuiddeSteps } from 'ducks'
import { firebaseConfig } from 'env'

import { useAuth, useBoolean } from 'hooks'
import { useScaleFactor } from 'UI/Routes/quick-guidde/hooks'

const uploadSnapshot = async (
    blobUrl: string,
    uid: string,
    playbookId: string
): Promise<string> => {
    const bucket = 'gs://' + `${firebaseConfig.projectId}-temporary`
    const storagePath = `quickguiddes/${uid}/${playbookId}/uploads`
    const storageUrl = `${storagePath}/${uuid()}.png`

    const response = await fetch(blobUrl)
    const blob = await response.blob()

    await uploadWithSignedUrl(bucket + '/' + storageUrl, blob)
    return getFirebaseUrl(storageUrl, true)
}

const generateImageLayer = async (
    chunk: TimelineSnapshotChunkType,
    windowDimensions: StepType['windowDimensions'],
    scaleFactor: number,
    uploadData: {
        playbookId: string
        uid: string
    }
): Promise<ImageShapeType> => {
    const { width, height, imageUrl } = chunk

    const { x, y, scale } = getLayerCoordinates(width, height, windowDimensions, scaleFactor)

    const fileUrl = await uploadSnapshot(imageUrl, uploadData.uid, uploadData.playbookId)

    return {
        type: Shape.Image,
        x,
        y,
        scaleX: scale,
        scaleY: scale,
        width,
        height,
        isBackground: true,
        id: uuid(),
        url: fileUrl
    }
}

type Props = {
    language: QuickGuiddeType['language']
    chunks: TimelineChunksType
    onDone: () => void
}

export const SaveSplitsButton = memo(({ chunks, language, onDone }: Props) => {
    const { playbookId } = useParams<{ playbookId: string }>()
    const dispatch = useDispatch()

    const { uid } = useAuth()
    const { extractVideoStep } = useContext(VoiceOverContext)

    const loading = useBoolean()
    const setLoading = loading.set

    const scaleFactor = useScaleFactor()

    const { activeStep, steps } = useSelector(state => state.qgEditor.present)
    const { layers, windowDimensions } = steps[activeStep]

    const videoLayer = layers.find(layer => layer.type === Shape.Video) as VideoShapeType

    const convertChunksToSteps = () => {
        return Promise.all(
            chunks.map(async chunk => {
                const id = uuid()

                if (checkIsVideoChunk(chunk)) {
                    // @TODO rename to something more clear
                    extractVideoStep({
                        playbookId,
                        start: chunk.start,
                        end: chunk.end,
                        languageCode: language?.langCode || 'en-US',
                        languageName: language?.langName || 'English',
                        stepId: id,
                        videoUrl: videoLayer.sourceVideoUrl
                    })
                }

                const { audioNote, subtitles, ...bareStep } = steps[activeStep]

                return {
                    ...bareStep,
                    id,
                    ...(checkIsSnapshotChunk(chunk) && {
                        drawnScreenshot: '',
                        previewScreenshot: ''
                    }),
                    duration: checkIsVideoChunk(chunk) ? chunk.end - chunk.start : chunk.duration,
                    layers: await Promise.all(
                        layers.map(async layer => {
                            if (layer.type !== Shape.Video) return layer

                            switch (chunk.type) {
                                case 'snapshot':
                                    return await generateImageLayer(
                                        chunk,
                                        windowDimensions,
                                        scaleFactor,
                                        {
                                            playbookId,
                                            uid
                                        }
                                    )
                                case 'video':
                                    return Promise.resolve({
                                        ...layer,
                                        id: uuid(),
                                        start: chunk.start,
                                        end: chunk.end
                                    })
                                default:
                                    return layer
                            }
                        })
                    )
                } as StepType
            })
        )
    }

    const saveChunks = async () => {
        setLoading(true)

        const { start: chunkStart, end: chunkEnd } = chunks[0] as TimelineVideoChunkType
        const isOriginalTime = videoLayer.start === chunkStart && videoLayer.end === chunkEnd

        if (chunks.length === 1 && isOriginalTime) {
            onDone()
            setLoading(false)
            return
        }

        const newSteps = [
            // steps before
            ...steps.slice(0, activeStep),
            // insert new steps from chunks
            ...(await convertChunksToSteps()),
            // steps after
            ...steps.slice(activeStep + 1)
        ]

        dispatch(setQuickGuiddeSteps(newSteps, true))
        onDone()
        setLoading(false)
        logToAnalytics('editVideoStep_saveBtn_clicked', { chunks })
    }

    return (
        <Button
            fullWidth
            variant="contained"
            disabled={loading.isTrue}
            endIcon={loading.isTrue ? <CircularProgress size={16} /> : null}
            onClick={saveChunks}
        >
            Save
        </Button>
    )
})
