import { type ReactNode } from 'react'
import { useDispatch } from 'react-redux'

import dayjs from 'dayjs'

import {
    Button,
    ThemeProvider,
    spacings,
    Icon,
    Typography,
    LinearProgress
} from '@guidde/design-system'
import { AppBar, Box, Stack } from '@mui/material'
import { faStar } from '@fortawesome/pro-solid-svg-icons'

import { useAuth, useOrganizationUsers, useServiceUsage } from 'hooks'
import { showPlanDialog } from 'ducks'

export const planNotificationheight = 60

// @TODO:
// remove `RoleDowngradeBanner` once ticket will be verified

type Props = {
    showPlaybooksLimit: boolean
    showMaxSeatsLimit: boolean
    showRoleDowngrade: boolean
    showIsTrialPlan: boolean
}

export const PlanUsageNotification = ({
    showPlaybooksLimit,
    showMaxSeatsLimit,
    showRoleDowngrade,
    showIsTrialPlan
}: Props) => {
    const { uid } = useAuth()
    const { trialDaysLeft = 0, planName, playbooksQuota, usedPlaybooksSlots } = useServiceUsage()

    const { orgUsers } = useOrganizationUsers()
    const currentOrgUser = orgUsers?.find(user => user.uid === uid)

    if (showIsTrialPlan) {
        return (
            <PlanUsageNotificationContainer>
                <Stack spacing={spacings['lg']} direction="row" alignItems="center">
                    <Typography variant="text" size="md" fontWeight="semibold" color="common.white">
                        Free {planName} trial • {trialDaysLeft} day{' '}
                        {Number(trialDaysLeft) > 1 ? 's' : ''} left
                    </Typography>
                    <UpgradePlanButton />
                </Stack>
            </PlanUsageNotificationContainer>
        )
    }

    if (showRoleDowngrade) {
        return (
            <PlanUsageNotificationContainer>
                <Stack spacing={spacings['lg']} direction="row" alignItems="center">
                    <Typography variant="text" size="md" fontWeight="semibold" color="warning.300">
                        Your account has exceeded its seat limit.
                    </Typography>
                    <Typography variant="text" size="md" fontWeight="semibold" color="common.white">
                        You are set to become a Viewer on{' '}
                        {dayjs(currentOrgUser?.viewerOnlyAfterMs).format('MMM Do')}. Contact your
                        Admin for more details
                    </Typography>
                    <UpgradePlanButton />
                </Stack>
            </PlanUsageNotificationContainer>
        )
    }

    if (showMaxSeatsLimit) {
        return (
            <PlanUsageNotificationContainer>
                <Stack spacing={spacings['lg']} direction="row" alignItems="center">
                    <Typography variant="text" size="md" fontWeight="semibold" color="common.white">
                        Your plan has reached its seat limit
                    </Typography>
                    <UpgradePlanButton />
                </Stack>
            </PlanUsageNotificationContainer>
        )
    }

    if (showPlaybooksLimit) {
        return (
            <PlanUsageNotificationContainer>
                <Stack spacing={spacings['lg']} direction="row" alignItems="center">
                    <Stack spacing={spacings['xl']} direction="row" alignItems="center">
                        <Typography
                            variant="text"
                            size="md"
                            fontWeight="semibold"
                            color="common.white"
                        >
                            Your account has almost reached its limit
                        </Typography>
                        <Box width={95}>
                            <LinearProgress total={playbooksQuota} value={usedPlaybooksSlots} />
                        </Box>
                        <Typography variant="text" size="md" color="common.white">
                            {usedPlaybooksSlots}/{playbooksQuota} videos
                        </Typography>
                    </Stack>
                    <UpgradePlanButton />
                </Stack>
            </PlanUsageNotificationContainer>
        )
    }
}

const UpgradePlanButton = () => {
    const dispatch = useDispatch()

    return (
        <Button
            onClick={() => dispatch(showPlanDialog('upgradePlanDialog'))}
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<Icon icon={faStar} />}
        >
            Upgrade Plan
        </Button>
    )
}

type ContainerProps = {
    children: ReactNode
}

const PlanUsageNotificationContainer = ({ children }: ContainerProps) => (
    <ThemeProvider>
        <AppBar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
            <Box
                height={planNotificationheight}
                px={spacings['6xl']}
                py={spacings['lg']}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={theme => ({
                    background: theme => theme.palette.primary[700],
                    color: theme.palette.common.white
                })}
            >
                {children}
            </Box>
        </AppBar>
    </ThemeProvider>
)
